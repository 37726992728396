<template>
  <section>
    <div class="richtext">
      <h2 style="text-align: left">
        <span style="font-size: 24px">Functions of pulp and paper pumps</span>
      </h2>
      <p><br /></p>
      <p>
        In the field of papermaking, polyacrylamide which is short for PAM is
        widely used as a retention aid, filter and evenness agent in order to
        improve the paper quality, pulp dewatering performance, retention rate
        of the fibers and filters, reduce raw material consumption and
        environmental pollution, as a dispersant, can improve the uniformity of
        the paper.
      </p>
      <p><br /></p>
      <p>
        Polyacrylamide is mainly used in the papermaking industry in the
        following two aspects.
      </p>
      <p><br /></p>
      <p>
        Firstly, metering PAM can improve the retention rate of filters and
        pigments to reduce the loss of raw materials and environmental
        pollution.
      </p>
      <p><br /></p>
      <p>
        Secondly, adding PAM by the pulp pump in the paper material improves the
        strength of the paper. It can increase the retention rate of fine fibers
        and packing particles on the net and accelerate the dehydration of
        paper. The mechanism of action of pam is that the slurry is electrically
        neutralized or bridged and the flocculation is reminded on the press
        cloth. The formation of flocs can also make the water in the slurry
        filter out more easily, reduce the loss of fiber in white water, reduce
        environmental pollution and improve the efficiency of filtration and
        precipitation equipment.
      </p>
      <p><br /></p>
      <p style="text-align: center">
        <img
          src="/uploads/image/20201123/Pulp_and_Paint.jpg"
          title="Pulp and Paint Pump"
          alt="Pulp and Paint Pump"
          width="681"
          height="448"
          border="0"
          vspace="0"
          style="width: 681px; height: 448px"
        />
      </p>
      <p><br /></p>
      <p>
        In the paint and ink industry, besides the various types and colors of
        paint and ink, it is also important to types of pumps used in paper
        industry decolorizing agent, bleacher and dye-fixing agent. Newdose
        paper pump has more than 20 years of experience in this field and
        delivers various pumps, each with its own specifications. We offer
        customized solutions to meet the ending needs.
      </p>
      <p><br /></p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Pulp and Paper Pump",
}
</script>